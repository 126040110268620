@import url("./components.css");
@import url("./utilities.css");
@import url("./tailwind_import_development.css");

html {
  position: relative;
  overflow: hidden;
  height: 100vh;
  min-height: 100vh;
}

body {
  @apply font-sans text-io-main;

  overflow: hidden;
  margin: 0;
  padding: 0;
  position: fixed;
  inset: 0;
  height: 100%;
  min-height: 100%;
}

.loader {
  @apply border-io-orange;

  border-top: 1px solid;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
