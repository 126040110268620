/* Alert Box */

/* Banner close animation */
.alert-banner input:checked ~ * {
  @apply animate-fade-out-up;
}

#navigation-bar {
  @apply h-full absolute lg:static flex overflow-hidden bg-white;
}

#mobile-nav-backdrop {
  @apply h-full w-full bg-black bg-opacity-50;

  /* Apply a blur effect. There is no tailwind class for this. */
  backdrop-filter: blur(4px);
}

.navigation-item {
  @apply rounded-lg bg-gray-100 flex items-center px-2 py-2 text-sm font-medium;

  /* Animation so that the hover state has a fade effect (with 150ms) */
  @apply transition duration-150;
}

/* The navigation item for the active navigation element (highlighted in blue) */
.navigation-item.active {
  @apply bg-blue-200 text-blue-800;
}

/* Hover state for the navigation item. */
.navigation-item:hover {
  @apply bg-gray-300 text-gray-900;
}

.item-list {
  @apply w-96;
}

.item-list a {
  @apply cursor-default;
}

a.active li {
  @apply bg-blue-400;
}

.item-list li .title {
  @apply text-sm font-medium;
}

.item-list a.active li .title {
  @apply text-white;
}

.item-list li .subtitle {
  @apply text-sm font-light;
}

.item-list a.active li .subtitle {
  @apply text-gray-100;
}

.highlight {
  @apply text-gray-900;
}

/**
This selector matches the "main" element, when its next to an item list.
When no item is selected (.index-route) The main view is hidden so that on
mobile devices the item list can take the full screen.
*/
aside.item-list.index-route + main {
  @apply hidden md:w-full;
}

#mobile-sidebar-wormhole {
  @apply absolute z-40;
}
